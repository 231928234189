.contact-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    min-height: 100vh;
    color: #ffffff;
    position: relative;
    
  }
  
.contact-info {
  flex: 1;
  padding-right: 2rem;

}

.contact-info h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #00bcd4;
}

.contact-info p {
  margin: 0.3rem 0;
}

.contact-icon {
  width: 30px;
  height: 30px;
  margin-right:15px;
}





.contact-image {
  width: 300px;
  height: 300px;
  background-color: #ccc; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* image fills the area without distortion */
  border-radius: 8px;
}

.contact-footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  color: #888888;
  font-size: 0.8rem;
}
