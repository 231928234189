.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  background-image: url('../images/mjy15.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  color: #ffffff;
  position: relative;
}

.title-section {
  margin-bottom: 2rem;
  padding-top: 200px;
}

.title-section h2 {
  padding-left: 20px;
}

.about-section {
  position: absolute;
  bottom: 8rem;  
  right: 2rem;
  color: #ffffff;
  background-color: #1e1e1e;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  max-width: 500px;
  font-size: 0.9rem;
  text-align: left;
}

.about-section h3 {
  margin: 0;
  font-size: 1rem;
  margin-bottom: 20px;
}

.about-section p {
  margin: 0.5rem 0 0;
}

.button {
  display: inline-flex;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

.button-logo {
  width: 24px;
  height: 24px;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.highlight {
  color: #007bff;
}


@media (max-width: 600px) {
  .about-section {
    position: static;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    padding: 1rem;
  }
}
